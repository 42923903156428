import jwt from 'jsonwebtoken';

const localStorage = window.localStorage;

export const getUserLogin = () =>
  localStorage.hasOwnProperty('token') && jwt.decode(localStorage.token);

export const getUserAccesses = () =>
  getUserLogin() && (getUserLogin().accesses || []);

export const checkAuth = () =>
  getUserLogin() && getUserLogin().exp * 1000 > Date.now();

export const checkAccess = access =>
  getUserLogin() && getUserAccesses().includes(access);
