import gql from 'graphql-tag';

const QUERY_GET_TOKEN = gql`
  query getToken {
    getToken @client
  }
`;

const MUTATION_SET_TOKEN = gql`
  mutation setToken($token: String) {
    setToken(token: $token) @client
  }
`;

const MUTATION_REMOVE_TOKEN = gql`
  mutation removeToken {
    removeToken @client
  }
`;

const MUTATION_SET_COMPANY = gql`
  mutation setCompany($code: String, $company: String) {
    setCompany(code: $code, company: $company) @client
  }
`;

export {
  QUERY_GET_TOKEN,
  MUTATION_SET_TOKEN,
  MUTATION_REMOVE_TOKEN,
  MUTATION_SET_COMPANY
};
