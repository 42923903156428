import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
  ApolloClient,
  // createHttpLink,
  InMemoryCache,
  ApolloProvider
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { MUTATION_SET_TOKEN, MUTATION_REMOVE_TOKEN } from './gql/token';
import { Alert } from './utils/swal';
import { checkAuth } from './utils/auth';

import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword'));

let localStorage = window.localStorage;
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
const customFetch = (uri, options) => {
  return fetch(localStorage.getItem('uri') || 'http://localhost:4000', options);
};
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(
    // createUploadLink({
    //   uri: 'http://localhost:4000'
    // })
    createUploadLink({
      fetch: customFetch
    })
  ),
  resolvers: {
    Query: {
      getToken: () => {
        return localStorage.getItem('token');
      }
    },
    Mutation: {
      setToken: (_, { token }) => {
        return localStorage.setItem('token', token);
      },
      removeToken: () => {
        return localStorage.clear();
      },
      setCompany: (_, { code, company }) => {
        localStorage.setItem(
          'uri',
          `https://${code.toLowerCase()}.trinusatextile.com`
        );
        localStorage.setItem('code', code);
        return localStorage.setItem('company', company);
      }
    }
  }
});
client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache'
  },
  query: {
    fetchPolicy: 'no-cache'
  }
};

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(checkAuth());
  useEffect(() => {
    if (isLoggedIn !== checkAuth()) setIsLoggedIn(checkAuth());
  }, [isLoggedIn]);

  const login = (history, { isResetPassword, token }) => {
    client
      .mutate({
        mutation: MUTATION_SET_TOKEN,
        variables: {
          token
        }
      })
      .then(() => {
        Alert.fire({
          icon: 'success',
          title: 'Log in successfully'
        });
        setIsLoggedIn(true);
        if (isResetPassword) history.push('/reset-password');
        else history.push('/');
      })
      .catch(e => console.log(e.message || e));
  };

  const logout = () => {
    client
      .mutate({
        mutation: MUTATION_REMOVE_TOKEN
      })
      .then(() => {
        Alert.fire({
          icon: 'success',
          title: 'Log out successfully'
        });
        setIsLoggedIn(false);
      })
      .catch(e => console.log(e.message || e));
  };

  return (
    <ApolloProvider client={client}>
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={props => <Login {...props} login={login} />}
            />
            {!isLoggedIn && <Redirect to="/login" />}
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={props => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={props => <Page404 {...props} />}
            />
            {/* <Route
              exact
              path="/500"
              name="Page 500"
              render={props => <Page500 {...props} />}
            /> */}
            <Route
              path="/"
              name="Home"
              render={props => <TheLayout {...props} logout={logout} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </ApolloProvider>
  );
};

export default App;
