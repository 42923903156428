import Swal from 'sweetalert2';
import i18n from '../i18n';

const Alert = Swal.mixin({
  showConfirmButton: false,
  timer: 1500
});

const Toast = Swal.mixin({
  showConfirmButton: false,
  timer: 1500,
  toast: true
});

const DeleteConfirmation = Swal.mixin({
  text: i18n.t('alert.delete.description'),
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#2a1ab9',
  cancelButtonColor: '#d33',
  confirmButtonText: i18n.t('alert.confirm'),
  cancelButtonText: i18n.t('alert.cancel'),
  reverseButtons: true,
  focusConfirm: false
});

const LoadingAlert = Swal.mixin({
  allowOutsideClick: false,
  onOpen: () => {
    Swal.showLoading();
  }
});

const CancelConfirmation = Swal.mixin({
  title: 'Reason for cancellation',
  input: 'textarea',
  inputLabel: 'Reason',
  inputPlaceholder: 'Enter your reason',
  showCancelButton: true,
  inputValidator: value => {
    if (!value) {
      return 'You need to write something!';
    }
  }
});

export { Alert, Toast, DeleteConfirmation, LoadingAlert, CancelConfirmation };
