import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLocalization from "./locales/en.json"
import idLocalization from "./locales/id.json"

const resources = {
  en: {
    translation: enLocalization
  },
  id: {
    translation: idLocalization
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "id",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;
